import styled from "styled-components";

const Styling = styled.div`
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999999999999;

  a {
    z-index: 100000;
    position: relative;
    bottom: 0;
    left: 0;
  }

  .iframe-wrapper {
    width: 100vw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
`;

export { Styling };
