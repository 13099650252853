import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Page3Form from "../components/page-components/Campaigns/Page3/Page3Form";
import gsap from "gsap";

export default function Campaigns3({ data }) {
  const { pageData } = data.wpCampaign;

  useEffect(() => {
    const elements = ["footer", "header", ".contact_button_fixed"];
    gsap.to(elements, { opacity: "0", pointerEvents: "none" });
    gsap.to("#gatsby-focus-wrapper", { minHeight: "100vh" });

    return () => {
      const elements = ["footer", "header", ".contact_button_fixed"];
      gsap.to("#gatsby-focus-wrapper", { minHeight: "auto" });
      gsap.to(elements, { opacity: "1", pointerEvents: "all" });
    };
  }, []);

  return (
    <>
      <Page3Form />
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    wpCampaign(id: { eq: $id }) {
      seo {
        metaDesc
        title
      }
      slug
      pageData: acf_campaigns {
        page3TypeformEmbedCode
      }
    }
  }
`;
